import * as basicLightbox from 'basiclightbox'
import 'basiclightbox/src/styles/main';

window.basicLightbox = basicLightbox;

/* Lightbox has a link on rel=[lightbox] and a containing <img> */
export const mountLightboxes = () => {
  const possibleLightboxes = document.querySelectorAll('a[rel=lightbox]');
  [].forEach.call(possibleLightboxes, (el) => {
    el.addEventListener('click', (e) => {
      e && e.preventDefault();
      const instance = basicLightbox.create(`
        <img src="${el.href}" />
      `)
      instance.show();
    });
  });
}
