import React from 'react';
import PropTypes from 'prop-types';

class QuantitySelect extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  render() {
    const { value, onChange } = this.props;
    const canDecrement = value > 0;

    return (
      <div className="input-group quantity-select">
        <div className="input-group-prepend">
          <button className="btn btn-primary" 
            disabled={!canDecrement}
            onClick={ e => canDecrement && onChange(value-1) }
            type="button">-</button>
        </div>
        <input type="text" 
          className="form-control" 
          value={value} 
          onChange={(e) => onChange(parseInt(e.target.value)) }/>
        <div className="input-group-append">
          <button 
            className="btn btn-primary" 
            type="button"
            onClick={ e => onChange(value+1) }>+</button>
        </div>
      </div>
    );
  }
}

export default QuantitySelect;
