import React from 'react';
import PropTypes from 'prop-types';
import PostcodeSelect from './PostcodeSelect';
import 'axios';


const FormGroup = ({children}) => (
  <div className="form-group">
    {children}
  </div>  
)

const Label = ({children, htmlFor}) => (
  <label className="col-sm-3 control-label" htmlFor={htmlFor}>
    {children}
  </label>
)

const Textfield = (props) => (
  <div className="col-sm-9">
    <input type="textfield" className="form-control" {...props} />
  </div>
)


class AddressFields extends React.Component {
  static propTypes = {
    line_1: PropTypes.string,
    line_2: PropTypes.string,
    city: PropTypes.string,
    postal_code: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    namePrefix: PropTypes.string.isRequired,
  }

  handleChange = (e) => {
    console.log(e);
    this.props.onChange(e.target.name.match(/\[(.*)\]/)[1], e.target.value);
  }

  renderField(label, name) {
    const { namePrefix } = this.props;
    const id = namePrefix + "_" + name;
    return (
      <FormGroup>
        <Label htmlFor={namePrefix+'['+name+']'}>{label}</Label>
      <Textfield name={namePrefix+'['+name+']'} id={id} value={this.props[name]} onChange={this.handleChange} />
      </FormGroup>    
    )
  }

  renderLatLong() {
    const { latitude, longitude, namePrefix } = this.props;
    return (
      <div>
        <input type="hidden" value={latitude} name={namePrefix+'[latitude]'} />
        <input type="hidden" value={longitude} name={namePrefix+'[longitude]'} />
      </div>
    )
  }

  render() {
    const { line_1, line_2, city, postal_code, onChange } = this.props;
    return (
      <div className="AddressFields">
        {this.renderField('Address', 'line_1')}
        {this.renderField('', 'line_2')}
        {this.renderField('City or town', 'city')}
        {this.renderField('Postcode', 'postal_code')}
        {this.renderLatLong()}
      </div>
    );
  }
}


class AddressInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: props.values,
    }

    this.handleSelectAddress = this.handleSelectAddress.bind(this);
  }

  handleSelectAddress = (address) => {
    this.setState({
      values: address,
    })


    window.updateBookingHints && window.updateBookingHints({
      postcode: address.postal_code,
    });
  }

  handleEnterManually = (e) => {
    e && e.preventDefault();
    this.setState({
      values: {
        line_1: '  ',
        line_2: '',
        city: '',
        postal_code: '',
      },
    })
  }

  handleChangeAddress = (field, value) => {
    this.setState({
      values: { 
        ...this.state.values,
        [field]: value,
      }
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      values: nextProps.values,
    })
  }

  render() {
    const { isEditing, values } = this.state;
    const hasAddress = values.line_1 != '' && values.line_1 != null;

      return (
        <div className="form-horizontal">
          {!hasAddress &&
            <div className="form-group">
              <label className="control-label col-sm-3">Postcode</label>
              <div className="col-sm-9">
                <PostcodeSelect onSelect={this.handleSelectAddress} />
                <p style={{marginTop: 10}}><a href="#" onClick={this.handleEnterManually}>Enter address manually</a></p> 
              </div>
            </div>
          }


          { hasAddress &&
            <AddressFields {...this.state.values} namePrefix={this.props.namePrefix} onChange={this.handleChangeAddress} /> }

        </div>
      );
    }
}

AddressInput.propTypes = {
  values: PropTypes.object,
  namePrefix: PropTypes.string.isRequired,
}

export default AddressInput;
