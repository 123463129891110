import React from 'react';
import PropTypes from 'prop-types';
import ItemIcon from './ItemIcon';

const AddableItemVariants = ({variants, onAdd}) => {
  return(
    <table className="_variants">
      <tbody>
      {variants.map( variant => 
        <tr key={variant.id}>
          <td>{variant.variant_name}</td>
          <td className="_add">
            <button onClick={(e) => { e.preventDefault(); onAdd(variant.id)}} className="btn btn-primary">Add</button>
          </td>
        </tr>   
      )}
      </tbody>
    </table>
  )
}


const AddableItem = ({item, onAdd}) => {
  return (
    <div className="AddableItem"> 
      <ItemIcon svg={item.icon.svg} />
    
      <div className="_details">
        <div className="_name">{item.name}</div>
        {item.variants.length != 1 && 
          <AddableItemVariants variants={item.variants} 
            onAdd={onAdd} />}
      </div>

      {item.variants.length == 1 &&
        <div className="_actions">
          <button onClick={(e) => {
            e.preventDefault();
            onAdd(item.variants[0].id);
          }} className="btn btn-primary">Add</button>
        </div>}
    </div>
  )
}

AddableItem.propTypes = {
  item: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
}

class ManifestItemsPicker extends React.Component {
  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
  }

  state = {
    searchText: '',
  }

  handleChangeSearchText = (e) => {
    this.setState({
      searchText: e.target.value,
    })
  }

  handleAdd(itemVariantId) {
    this.setState({
      searchText: '',
    })
    this.props.onAdd(itemVariantId);
  }

  filteredItemTypes = () => {
    const { items } = this.props;
    const { searchText } = this.state;

    return items.filter( item => {
        const searchString = (item.name + " " 
          + item.aliases
          + item.variants.map(variant => variant.variant_name).join(' ')
        ).toLowerCase();
        return searchString.indexOf(searchText.toLowerCase()) !== -1
    });
  }

  render() {
    const { searchText } = this.state;

    const addableItemTypes = this.filteredItemTypes();
    return (
      <div className="ManifestItemsPicker">
        <input type="search"
          className="form-control input-search"
          value={searchText}
          placeholder="Start typing an item to search..."
          onChange={this.handleChangeSearchText} />

        <div className="_items">
          {addableItemTypes.map( item => 
             <AddableItem item={item} key={item.id} onAdd={this.handleAdd} /> 
          )}
        </div>
      </div>
    );
  }
}
ManifestItemsPicker.propTypes = {
  onAdd: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
}

export default ManifestItemsPicker;
