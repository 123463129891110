require("@rails/ujs").start()

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo;

require("@rails/activestorage").start()

import * as bootstrap from "bootstrap"

import "../stylesheets/base/index"

import { mountLightboxes } from '../utilities/lightbox';

document.addEventListener("turbo:load", () => {
  //$('[data-toggle="tooltip"]').tooltip()
  //$('[data-toggle="popover"]').popover()
});

import SlotPicker from '../components/SlotPicker';
import ManifestInput from '../components/ManifestInput';
import AddressInput from '../components/AddressInput';
import GiftaidStatus from '../components/GiftaidStatus';
import InstoreShopSelect from '../components/InstoreShopSelect';

import mountComponents from '../utilities/mounter';
import { redact } from '../utilities/redaction';

// Shoelace
/* Can't use it because server is so out of date :( 
import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/components/details/details.js';
*/

window.API_BASE = '/api/v0/';

window.objectValues = function(obj) {
  var res = [];
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      res.push(obj[i]);
    }
  }
  return res;
}




document.addEventListener("turbo:load", function() {
  console.log("Turbo loaded")

  mountComponents({
    SlotPicker,
    ManifestInput,
    AddressInput,
    GiftaidStatus,
    InstoreShopSelect,
  });

  mountLightboxes();

  redact();

  // Initialise Bootstrap Dropdowns
  var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
  var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    return new bootstrap.Dropdown(dropdownToggleEl)
  })
});


