import * as React from 'react';
import axios from 'axios';
import * as moment from 'moment';


import '../stylesheets/SlotPicker.scss';


const STATUSES = {
  available: {
    className: 'Slot is-open',
    text: 'Available',
  },
  unavailable: {
    className: 'Slot is-closed',
    text: 'Not available',
  },
  recommended: {
    className: 'Slot is-preferred',
    text: 'Recommended',
  }
}


const Slot = ({slot, onClick, context}) => {
  const stat = STATUSES[slot.status];
  return (
    <a onClick={onClick} href="#0" className={stat.className} style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
      data-turbo="false">
      <span style={{fontWeight: 'bold'}}>{stat.text}</span>
      {context == 'instore' &&
        <div style={{fontSize: 14}}>
          <div>You have {slot.job_count} jobs booked.</div>
        </div>}
    </a>
  );
}

const Day = ({date, children}) => {
  const formattedDate = moment(date).format('dddd D MMM');
  return (
    <div className="Day row" >
    <div className="col-sm-3" style={{paddingTop: 10, paddingBottom: 10}}><strong>{formattedDate}</strong></div>
      <div className="col-sm-9">
        {children}
      </div>
    </div>  
  );
}

type SlotPickerProps = {
  shopId: number,
  inputName: string,
  currentSlotId?: number,
  context: string,
}

class SlotPicker extends React.Component<SlotPickerProps> {
  state = {
    dates: [],
    isLoading: true,
    currentSlotId: null,
    shopId: null,
  }

  constructor(props) {
    super(props);
    this.state.currentSlotId = props.currentSlotId;
    this.state.shopId = props.shopId;
  }

  _fetchSlots(shopId) {
    this.setState({
      isLoading: true,
    });

    const { context } = this.props;
    const apiUrl = `/api/v0/slots/availability?shop_id=${shopId}&context=${context}`;
    axios.get(apiUrl).then( response => {
      console.log(response.data.data);
      this.setState({
        dates: response.data.data,
        isLoading: false,
      });
    });
  
  }

  componentDidMount() {
    if(this.props.shopId !== null) {
      this._fetchSlots(this.props.shopId);
    }

    // This is called by the shop picker, when the shop changes.
    (window as any).updateShop = (shopId) => {
      console.log("Shop updated", shopId);
      if(shopId == this.state.shopId) {
        return;
      }

      // Get the slots for the new shop
      this._fetchSlots(shopId);

      // Check if the currentSlot is still in the list of slots
      if(this.getSlot(this.state.currentSlotId)) {
        this.setState({
          shopId: shopId,
        })
      } else {
        this.setState({
          currentSlotId: null,
          shopId: shopId,
        })
      }
    }
  }

  handleSelectSlot(slotId) {
    console.log("Selected slot", slotId);
    const { context } = this.props;
    const slotStatus = this.flatten(this.state.dates.map(date => date.slots)).find( ss =>  ss.slot.id == slotId ); 
    console.log(slotStatus);
    if(context != 'backroom' && slotStatus.status == 'unavailable') {
      alert("That day is closed.");
      return false;
    }
    this.setState({
      currentSlotId: slotId,
    })
  }

  getSelectedSlot() {
    if(!this.state.currentSlotId) return null;
    return this.getSlot(this.state.currentSlotId);
  }

  getSlot(id) {
    return this.getAllSlots().find( slot => slot.id == id );
  }

  getAllSlots() {
    return this.flatten(this.state.dates.map( date => date.slots.map( s => s.slot ) ));
  }

  flatten(src) {
    return [].concat.apply([], src);
  }

  handleSelectDifferentSlot = (e) => {
    e && e.preventDefault();
    console.log("click")
    this.setState({currentSlotId: null});
  }

  renderCurrentSlot() {
    const slot = this.getSelectedSlot();
    return (
      <div className="SlotPickerSelected">
        {this.renderInput()}
        <a href="#0" onClick={this.handleSelectDifferentSlot} 
          className="pull-right btn btn-primary">Change</a>
        <p>We will do this on <strong>{moment(slot.date).format('dddd Do MMMM')}</strong>.</p>
      </div>
    )
  }

  renderInput() {
    return (
      <input type="hidden" name={this.props.inputName} value={this.state.currentSlotId} />  
    );
  }

  render() {
   if(this.state.shopId == null) return <p>Choose a shop above.</p>;
   if(this.state.isLoading) return (
      <div className="SlotPickerLoading">
        Fetching timeslots...
      </div>
    ) 
    if(this.state.currentSlotId && this.getSelectedSlot()) {
      return this.renderCurrentSlot();   
    }


    const { context } = this.props;
    return (
      <div className="SlotPicker" data-turbo="false">
      {this.state.dates.map( d => 
        <Day date={d.date} key={d.date}>
          { d.slots.length > 0 ?
            d.slots.slice(0,1).map( slot => 
              <Slot slot={slot} context={context} onClick={(e) => {this.handleSelectSlot(slot.slot.id); } } key={slot.id} /> 
            ) : <p key={d.date}>Not available</p> }
        
        </Day>
      )}
      </div>
    )
  }
}
export default SlotPicker;
