import * as React from 'react';

const EXPLANATIONS = {
  'STANDARD': {
    'POTENTIAL': "The customer needs to be signed up for Gift Aid.",
    'CLIENT_ENROLLED': "The customer is already signed up with the Charity.",
    'NOT_INTERESTED': "The customer is not eligible or interested in Gift Aid.",
  },
  'MAGIC': {
    'POTENTIAL': "Boxmove will invite the customer to complete a GiftAid Declaration.",
    'CLIENT_ENROLLED': "The customer is already signed up with the Charity.",
    'NOT_INTERESTED': "We will not not send the customer anything about GiftAid.",
  },
}

type Props = {
  value: string,
  flow: string,
  reference: string,
}

class GiftaidStatus extends React.Component<Props> {
  state = {
    value: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      value: (props.value || ''),
    }
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    })
  }

  renderExplanation() {
    const { flow } = this.props;
    let explanation = EXPLANATIONS[flow][this.state.value];
    

    return (
      <div className="alert alert-info alert-outline-coloured mt-3">
        <div className="alert-icon">
          <i className="fas fa-certificate"></i>
        </div>
        <div className="alert-message">
          {explanation}
        </div>
      </div>
    );
  }

  render() {
    const { value } = this.state;
    return (
      <div>
        <div className="form-group">
          <select name="job[giftaid_status]" className="form-control" value={value} onChange={this.handleChange} required={true}>
            <option value="">Choose status</option>
            <option value="POTENTIAL">Potential GiftAider</option>
            <option value="CLIENT_ENROLLED">Existing GiftAider</option>
            <option value="NOT_INTERESTED">Definitely not interested</option>
          </select>

          { value != 'NOT_INTERESTED'  && 
            <div className="form-group mt-3">
              <label htmlFor="Job_giftaid_reference">Your GiftAid reference / Donor ID</label>
              <input name="job[giftaid_reference]" defaultValue={this.props.reference} className="form-control" placeholder="Enter a donor ID if you have one."/>
            </div>
          }

          {this.renderExplanation()}
        </div>
      </div>
    );
  }
}

export default GiftaidStatus;
