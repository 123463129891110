
export const redact = () => {
  if(!window.__redact) return;
  document.body.classList.add('redaction-wrapper');

  console.log("Redacting...");
  [].forEach.call(document.querySelectorAll('.redact'), (el) => {
    console.log(el.innerText);
    const words = el.innerText.split(" ");
    el.innerHTML = '';
    el.classList.remove('redact');
    const newInner = words.map( word => {
      const initialLetter = document.createElement('span');
      initialLetter.innerText = word[0];
      el.appendChild(initialLetter);
      const redacted = document.createElement('span');
      redacted.innerText = word.substring(1) + " ";
      redacted.classList.add('redact');
      el.appendChild(redacted);
    })
  });
}
