import { Schema, arrayOf, normalize } from 'normalizr';
import _ from 'lodash';

const itemType = new Schema('itemTypes');
const itemVariant = new Schema('itemVariants');

itemType.define({
  variants: arrayOf(itemVariant),
})

export const normalizeItemTypes = (data) => {
  data = normalize(data, arrayOf(itemType));

  // Go over the itemTypes and add an itemType attribute
  // so that we can get to itemType from itemVariant
  _.values(data.entities.itemTypes).map( type => {
    type.variants.map(variant => {
      data.entities.itemVariants[variant].itemType = type.id;
    });
  });

  return data;
}
