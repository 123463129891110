import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import QuantitySelect from './QuantitySelect';
import ManifestItemsPicker from './ManifestItemsPicker';
import ItemIcon from './ItemIcon';
import { normalizeItemTypes } from '../api';

import '../stylesheets/ManifestInput.scss';


class ManifestList extends React.Component {
  render() {
    let {itemVariants, onChangeQuantity} = this.props;

    if(itemVariants.length == 0) {
      return <div className="alert alert-info alert-outline-coloured">
        <div className="alert-message">
          Choose some items from the list.
        </div>
      </div>;
    }

    return (
      <table className="table ManifestList">
        <tbody>
          {itemVariants.map( variant => (
            <tr key={variant.id}>
              <td>{variant.full_name}</td>
              <td className="_quantity">
                <QuantitySelect value={variant.quantity} 
                  onChange={ newQuantity => onChangeQuantity(variant.id, newQuantity) } />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
ManifestList.propTypes = {
  itemVariants: PropTypes.array.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
}

class ManifestInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entities: null,
      isLoading: true,
      selectedItemVariants: props.value,
    }
    
    this.handleAddToManifest = this.handleAddToManifest.bind(this);
    this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
  }

  componentDidMount() {
    if(window.STORE && window.STORE.itemTypes) {
      this.setState({
        entities: normalizeItemTypes(window.STORE.itemTypes).entities,
        isLoading: false,
      });
    } else {
      this.fetchItemTypes();
    }
  }

  fetchItemTypes = () => {
    let url = this.props.is_transfer ? '/item_types?for_transfer=true' : '/item_types';
    axios.get(API_BASE + url, { params: {
      direction: this.props.direction,
      client_id: this.props.client_id,
    }})
      .then((response) => {
        this.setState({
          entities: normalizeItemTypes(response.data.data).entities,
          isLoading: false,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange() {
    this.props.onChange && this.props.onChange( this.state.selectedItemVariants )
    window.updateDeliveryCharge && this.props.name.indexOf('delivery') != -1 && window.updateDeliveryCharge({
      units: this.getBillingUnits(),
    })

    const manifestType = this.props.name.indexOf('delivery') != -1 ? 'delivery' : 'collection';
    window.updateBookingHints  && window.updateBookingHints({
      [`${manifestType}_manifest_data`]: this.getJSON(),
    })
  }

  getBillingUnits() {
    return this.getSelectedItemVariants().reduce( (sum,variant) => (
      sum + variant.quantity * variant.billing_units
    ),0);
  }

  handleAddToManifest(variantId) {
    let { selectedItemVariants } = this.state;

    // Check if that variant is already selected 
    let existingSelection = selectedItemVariants.find( variant => variant.id == variantId );
    if(existingSelection) {
      return;
    }

    this.setState({
      selectedItemVariants: [ ...selectedItemVariants, { id: variantId, quantity: 1 } ],
    }, this.handleChange);
  }

  getItemTypes() {
    return objectValues(this.state.entities.itemTypes).filter( itemType => 
      (!this.props.hideInternal && !itemType.for_internal_client_use)
    ).map( itemType => {
      return Object.assign({}, itemType, {
        variants: itemType.variants.map( variantId => {
          return this.state.entities.itemVariants[variantId];
        })
      })
    }).sort( (a,b) => {
     return (b.popularity - a.popularity);
    })
  }

  getSelectedItemVariants() {
    return this.state.selectedItemVariants.map( ({id, quantity}) => {
      let variant = this.state.entities.itemVariants[id];
      return {...variant, quantity: quantity}; 
    });
  }

  handleChangeQuantity(variantId, quantity) {
    let newVariants = this.state.selectedItemVariants.map( el => {
      if(el.id == variantId) {
        return {
          id: variantId,
          quantity: quantity,
        }
      }
      return el;
    })

    this.setState({
      selectedItemVariants: newVariants,
    }, this.handleChange)
  }

  getJSON() {
    const { selectedItemVariants } = this.state;
    return JSON.stringify(selectedItemVariants);
  }

  render() {
    const { entities, isLoading } = this.state;
    const { name } = this.props;

    if(isLoading) {
      return (
        <p>Loading items...</p>    
      )
    }

    
    return (
      <div className="row">
        <div className="col-sm-7">
          <ManifestList 
            itemVariants={this.getSelectedItemVariants()} 
            onChangeQuantity={this.handleChangeQuantity} />
        </div>

        <div className="col-sm-5">
          <ManifestItemsPicker 
            items={this.getItemTypes()}
            onAdd={this.handleAddToManifest} />
        </div>

        <input type="hidden" 
          name={name}
          defaultValue={this.getJSON()}
          ref={(el) => this._inputEl = el} />
      </div>  
    );
  }
}
ManifestInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  direction: PropTypes.string,
  onChange: PropTypes.func,
  hideInternal: PropTypes.bool,
  client_id: PropTypes.number
}

export default ManifestInput
