import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import '../stylesheets/PostcodeSelect.scss';

const API_KEY = '5GXEmxBkc02CpVRDCPqU2A40113'


const parseAddressData = (data) => {
  return {
    line_1: data.line_1,
    line_2: data.line_2,
    line_3: data.locality,
    city: data.town_or_city,
    postal_code: data.postcode,
    latitude: data.latitude,
    longitude: data.longitude,
  }
}

const SearchButton = ({isFetching, onClick}) => {
  const button = isFetching ? <button className="btn btn-primary"
        disabled={true}>Searching...</button>
     : <button className="btn btn-primary"
         onClick={onClick}>Find address</button>

  return (
    <span className="input-group-append">
      {button}
    </span>    
  )
}
SearchButton.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

class AddressSelect extends React.Component {
  static propTypes = {
    suggestions: PropTypes.arrayOf(PropTypes.shape({
      address: PropTypes.string.isRequired,
      id: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    onSelect: PropTypes.func.isRequired,
  } 

  render () {
    const { suggestions, onSelect } = this.props;


    return (
      <ul className="AddressSelect">
        {suggestions.map(suggestion => (
          <li key={suggestion.id}
            onClick={() => onSelect(suggestion.id)}>{suggestion.address}</li>      
        ))}
      </ul>  
    );
  }
}


const UK_POSTCODE_REGEX = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/;

class PostcodeSelect extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func,
  }

  state = {
    isFetching: false,
    postcodeValue: '',
    suggestions: [],
    error: null,
  }

  findPostcode = (e) => { 
    e && e.preventDefault();
    const postcodeValue = this.state.postcodeValue.toUpperCase();

    if(postcodeValue.search(UK_POSTCODE_REGEX) == -1) {
      this.setState({
        error: "You need to enter a valid postcode.",
      });
      return false;
    }

    this.setState({
      isFetching: true,
      error: null,
    });


    axios.get(`https://api.getAddress.io/autocomplete/${postcodeValue}?api-key=${API_KEY}&all=true`)
      .then(json => {
        console.log(json);
        this.setState({
          isFetching: false,
          suggestions: json.data.suggestions,
        });
      }).catch( ex => {
        this.setState({
          isFetching: false,
          error: "There was an error fetching the data for this postcode",
        })
      })

    return false;
  }

  handlePostcodeChange = (e) => {
    this.setState({
      postcodeValue: e.target.value.toUpperCase(),
    })
  }

  handleSelect = (id) => {
    console.log(`Selected ${id}`)

    // get the full address
    axios.get(`https://api.getAddress.io/get/${id}?api-key=${API_KEY}&all=true`)
      .then(json => {
        this.setState({
          suggestions: [],
        });
        this.props.onSelect(
          parseAddressData(json.data)
        )
      });
  }

  render() {
    const { isFetching, suggestions, postcodeValue, error } = this.state;
    
    let className = 'PostcodeSelect';
    if (error !== null) {
      className += ' has-error';
    }

    return (
      <div className={className}>
          <div className="input-group">
              <input type="text" 
                className="form-control" 
                placeholder="Enter a postcode" 
                autoComplete="off"
                id="postcode_input"
                value={postcodeValue}
                onChange={this.handlePostcodeChange} />
                
              <SearchButton isFetching={isFetching}
                onClick={this.findPostcode} />
          </div>

        {error && 
          <p className="help-block">{error}</p>}

        {!isFetching && suggestions.length > 0 && 
          <AddressSelect suggestions={suggestions} 
            onSelect={this.handleSelect} /> }
      </div>
    );
  }
}

export default PostcodeSelect;
