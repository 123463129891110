import React from 'react';
import PropTypes from 'prop-types';

class ItemIcon extends React.Component {
  static propTypes = {
    svg: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div className="_item-icon" dangerouslySetInnerHTML={{__html: this.props.svg}} />
    )
  }
}

export default ItemIcon;
