import * as React from 'react';
import ShopSelect from './ShopSelect';

type InstoreShopSelectProps = {
  currentShop: {
    name: string,
    id: number,
    clientId: number,
  },
  selectedShopId: number,
  inputName: string,
}

class InstoreShopSelect extends React.Component<InstoreShopSelectProps> {
  state = {
    isEditing: false,
  }

  constructor(props) {
    super(props);
    if(props.currentShop.id == null) {
      this.state.isEditing = true;
    } else if (props.currentShop.id != this.props.selectedShopId) {
      this.state.isEditing = true;
    }
  }

  cancelEditing = (e) => {
    (window as any).updateShop(this.props.currentShop.id);
    this.setState({
      isEditing: false 
    });
  }

  renderEditing() {
    const { currentShop } = this.props;
    return (
      <div className="card card-outline">
        <div className="card-header">
          Which shop is this job for?
        </div>


        <div className="card-body">
          {currentShop.id != null &&
            <div className="text-right mb-2">
              <button className="btn btn-primary" onClick={this.cancelEditing}>Switch back to your shop</button>
            </div>
          }

          <p>If booking a stock transfer, 
            you should choose the <strong>receiving</strong> shop 
            from this list.</p>

          <ShopSelect value={this.props.selectedShopId} 
            inputName={this.props.inputName} 
            clientId={this.props.currentShop.clientId} />
        </div>
      </div>
    );
  }

  render () {
    if(this.state.isEditing) return this.renderEditing(); 
    const { currentShop } = this.props;
    return (
      <div className="text-right mb-3" style={{marginBottom: 15}}>
        <button className="btn btn-primary" onClick={ e => this.setState({isEditing: true}) }>
          <i className="fas fa-store mr-1"></i>
          Book this job for another shop</button>
      <input type="hidden" value={this.props.selectedShopId} 
        name={this.props.inputName} />
      </div>
    );
  }
}

export default InstoreShopSelect;
